import { useState } from 'react';
import { Popover } from 'react-tiny-popover'

const ProductPopover = ({ product }) => (
  <div className="popover bs-popover-auto fade show" data-popper-placement="bottom">
    <div className="popover-body">
      <h5>{product.product} - {product.sku}</h5>
      {product.status === "red" && <div className="shelf-item-content-status">Ausverkauft seit 47 Stunden</div>}
      {product.status === "yellow" && <div className="shelf-item-content-status">Ausverkauft seit 3 Stunden</div>}
      <table>
        <tbody>
          <tr>
            <td>Preis:</td>
            <td>{product.price}</td>
          </tr>
          <tr>
            <td>Profit:</td>
            <td>{product.profit}</td>
          </tr>
          <tr>
            <td>Ausverkaufsdauer:</td>
            <td>{product.salespeed}</td>
          </tr>
          <tr>
            <td>Nachfülldauer:</td>
            <td>{product.restockspeed}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
)

const Product = ({ product, totalWidth, showProduct }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div className={`shelf-item shelf-item-status-${product.status}`} style={{ width: `${product.width / totalWidth * 100}%` }}>
      <Popover isOpen={isPopoverOpen} onClickOutside={() => setIsPopoverOpen(false)} positions={['bottom']} content={<ProductPopover product={product} />}>
        <div className="shelf-item-inner" onClick={() => setIsPopoverOpen(true)}>
          {showProduct && <img src={process.env.PUBLIC_URL + "/products/" + product.image} />}
        </div>
      </Popover>
    </div>
  );
}

const Shelf = ({ products, showProducts, onHighlight }) => {
  const totalWidth = products.reduce((sum, product) => sum + product.width, 0);
  return (
    <>
      {products.map((product, i) => <Product key={i} product={product} totalWidth={totalWidth} showProduct={showProducts} />)}
    </>
  )
}

export default Shelf;
