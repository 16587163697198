import { useState, useEffect } from "react";

import Shelf from "./components/Shelf";
import TaskList from "./components/TaskList";
import Timeline from "./components/Timeline";

import { createFaker } from "./faker";

const fakeTasks = createFaker({
  product: [
    "Milka Zartherb 100g",
    "Milka Kuhflecken 100g",
    "Toblerone 100g",
    "Ritter Sport Rum 200g",
    "Merci Grosse Vielfalt 250g",
    "Merci Grosse Vielfalt 400g",
    "Storck Toffifee 125g",
    "Storck Toffifee 400g",
    "Mon Cheri Riegel 5ST 52g",
    "Mon Cheri 157g",
    "Ferrero Küsschen 5ST 44g",
    "Ferrero Küsschen 178g",
    "Ferrero Rocher 4ST 50g",
    "Raffaello 4ST 40g",
    "Ferrero kinder Schokolade 100g",
    "Yogurette Erdbeere 100g",
    "Ferrero kinder Country 23,5g",
    "Ferrero kinder Riegel 21g",
    "Kinder Überraschungsei 20g",
    "duplo 10ST 182g",
    "duplo 18,2g",
    "kinder Riegel 10x21g",
    "Ferrero kinder bueno 2ST 43g",
    "Trumpf Schogetten Vollm.Nuss 100g",
    "Trumpf Edle Tropfen Nuss-Obstbr.250g",
    "Trumpf Edle Tropfen Nuss-Obstl.250g",
    "After Eight Classic 200g",
    "Nippon Häppchen 200g",
    "Mr.Tom 2ST 80g",
    "Lindt Alpenvollmilch Nuss Tafel 300g",
    "Moritz Eiskonfekt Würfel 200g",
    "Guyl.Meeresfr.250g",
    "Lindt Marzipan Riegel 50g",
    "Lindt Alpenvollm.Extra 300g",
    "Lindt Vollmilch Tafel 100g",
    "Lindt Hauchdünne Täfelchen Vollm.125g",
    "Viba Nougat Classic Jumbo 150g",
    "Böhme Pfefferminz Creme Schokolade 100g",
    "Mara.Tafel Vollmilch 250g",
    "duplo Chocnut 26g",
    "Mara.Tafel Mint Krokant 250g",
    "Ferrero kinder Joy 20g",
    "Reber Mozart-Herzl 8ST 80g",
    "Niedereg.Marzipan Schwarzbrot 75g",
    "Niedereg.Marzipan Schwarzbrot 125g",
    "Niedereg.Marzipan Schwarzbrot 200g",
    "Niedereg.Marzipan Herzen Dose 6ST 75g",
    "Niedereg.Marzipan Klassiker 8ST 100g",
    "Niedereg.Marzipan Klassiker Var.24ST300g",
    "Lindt Ganznuss Tafel 100g"
  ],
  sku: [
    "7622400005190",
    "7614500010013",
    "4000417067203",
    "4014400901191",
    "4014400900217",
    "4014400400007",
    "4014400900576",
    "40084084",
    "4008400102528",
    "4008400151724",
    "4008400159027",
    "4008400160429",
    "4008400180427",
    "40084701",
    "40084060",
    "40084176",
    "40084077",
    "40084107",
    "4008400301020",
    "40084015",
    "4008400221021",
    "4008400935225",
    "4000607151101",
    "4000607432804",
    "4000607432101",
    "5000189363069",
    "4021700900021",
    "4021700800109",
    "7610400013864",
    "4047600012107",
    "5410976140122",
    "4000539381003",
    "7610400013857",
    "4000539001307",
    "4000539204500",
    "44000271",
    "4020486035002",
    "7310511800109"
  ],
  location: "Regal 15B-12",
  time: ["seit 3 Stunden", "seit 7 Stunden", "seit 18 Stunden", "seit 2 Wochen", "seit 15 Minuten"]
})

const fakeShelf = createFaker({
  status: [
    { value: "green",  weight: 1 },
    { value: "yellow", weight: 0.1 },
    { value: "red",    weight: 0.1 },
  ],
  width:    [3, 4, 5],
  product:  ["Milka Alpenmilch Schokolade", "Ritter Sport Nuss Mix", "Kinder Schokolade", "Toblerone", "Ferrero Rocher", "Lindt Lindor Kugeln", "Nestlé After Eight", "Cadbury Dairy Milk", "Ghirardelli Intense Dark Schokolade", "Lindt Goldhase"],
  sku:      "SKU: 121-221-4432",
  location: "Regal 15B-12",
  price:    ["1,99€", "1,29€", "2,99€", "3,99€", "4,99€", "5,99€", "6,99€", "7,99€", "8,99€", "9,99€"],
  profit:   ["0,99€", "0,29€", "1,97€"],
  salespeed:    ["14h", "4.4 Tage", "12h", "3.2 Tage", "2.1 Tage", "5.2 Tage"],
  restockspeed: ["14h", "4.4 Tage", "12h", "3.2 Tage", "2.1 Tage", "5.2 Tage"]
});

function zip(array1, array2) {
  return array1.map((element, index) => [element, array2[index]]);
}

function buildShelves(products, timeseries, time) {
  const result = []
  console.log(timeseries, time)
  if (timeseries && timeseries.levels.length > time) {
    const levels = timeseries.levels[time]
    zip(levels, products).forEach(([shelfLevels, shelfProducts]) => {
      result.push(zip(shelfLevels, shelfProducts).map(([level, product]) => ({
        ...product, 
        status: { "0": "red", "1": "yellow", "2": "green" }[level]
      })))
    })
  }
  return result
}

function App() {
  const [selectedTime, setSelectedTime] = useState(null);
  const [showProducts, setShowProducts] = useState(false);
  const [products,     setProducts]     = useState([]);
  const [timeseries,   setTimeseries]   = useState(null);

  useEffect(() => {
    (async () => {
      const prods = await (await fetch("/products/products.json")).json()
      const times = await (await fetch("/products/timeseries.json")).json()
      setProducts(prods)
      setTimeseries(times)
      setSelectedTime(times.overall.length - 1)
    })()
  }, [])

  return (
    <div className='container'>
      <h1 className="my-5"><i className='fa-solid fa-chevron-left'></i> Regal: 13-B Süsswaren</h1>
      <div className='row'>
        <div className='col-8'>
          <div style={{ height: "200px" }}>
            {timeseries && <Timeline data={timeseries} selectedX={selectedTime} onSelectX={setSelectedTime} />}
          </div>
          <div className="card">
            <div className="card-header">
              <a className="btn btn-secondary btn-sm pull-right" href="#" onClick={() => setShowProducts(!showProducts)}>
                {showProducts ? "Produkte ausblenden" : "Produkte einblenden"}
              </a>
            </div>
            <div className="card-header"></div>
            {buildShelves(products, timeseries, selectedTime).map((shelf) => (
              <>
                <div className="card-body p-2">
                  <Shelf products={shelf} showProducts={showProducts} />
                </div>
                <div className="card-header"></div>
              </>
            ))}
          </div>
        </div>
        <div className='col-4'>
          <TaskList tasks={fakeTasks(10)} />
        </div>
      </div>
    </div>
  );
}

export default App;
