import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  annotationPlugin
);

function range(start, end, step = 1) {
  const result = [];

  for (let i = start; i < end; i += step) {
    result.push(i);
  }

  return result;
}

const Timeline = ({ data, selectedX, onSelectX }) => {
  const chartData = {
    labels: range(0, data.overall.length),
    datasets: [{
      label: "Füllstand",
      data:  data.overall,
      borderColor: 'black',
      pointRadius: 0
    }]
  }

  const options = {
    onClick: (event, elements) => {
      const clickX = event.native.offsetX;
      const xScale = event.chart.scales.x;
      const xValue = xScale.getValueForPixel(clickX);
      onSelectX(xValue);
    },
    scales: {
      x: { display: false }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: selectedX ? [{
          type: 'line',
          xMin: selectedX,
          xMax: selectedX,
          yMin: 0,
          yMax: 1,
          borderColor: 'red',
          borderWidth: 2,
          adjustScaleRange: false
        }] : [],
      },
    },
    maintainAspectRatio: false
  };

  return <Line data={chartData} options={options} />;
};

export default Timeline;
