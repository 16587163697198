const TaskList = ({ tasks }) => (
  <div className="card">
    <div className="card-header">Packplan <a className="btn btn-secondary btn-sm pull-right" href="">Drucken</a></div>
    <ul className="list-group list-group-flush">
      {tasks.map((task, i) => (
        <li className="list-group-item" key={i}>
          <div className="d-flex w-100 justify-content-between">
            <h5 className="mb-1">{task.product}</h5>
            <small>{task.time}</small>
          </div>
          <p className="mb-1">{task.sku}</p>
          <small>{task.location}</small>
        </li>
      ))}
    </ul>
  </div>
)

export default TaskList;
