export function createFaker(template) {
  return (numResults) => {
    const sampleWeighted = (weightedOptions) => {
      const totalWeight = weightedOptions.reduce((sum, option) => sum + (option.weight || 1), 0);
      const randomValue = Math.random() * totalWeight;
      let cumulativeWeight = 0;

      for (const option of weightedOptions) {
        cumulativeWeight += (option.weight || 1);
        if (randomValue <= cumulativeWeight) {
          return option.value || option;
        }
      }
    };

    const generateRandomItem = (template) => {
      const item = {};

      for (const key in template) {
        if (template.hasOwnProperty(key)) {
          if (Array.isArray(template[key])) {
            item[key] = sampleWeighted(template[key]);
          } else {
            item[key] = template[key];
          }
        }
      }

      return item;
    };

    const shelf = [];
    for (let i = 0; i < numResults; i++) {
      const item = generateRandomItem(template);
      shelf.push(item);
    }

    return shelf;
  };
}
